<template>
  <div class="awardWiningStudents">
    <div class="flex-box">
      <div class="left">
        <el-button type="success" @click="addAwardWiningStudents">+新增</el-button>
        <!-- <el-button type="info">导入</el-button> -->
        <el-button @click="loadTemplate" type="info">下载导入模板</el-button>
        <el-upload action="/api/base/importAward.do"
                   :data="{type:2}"
                   class="display marginRightAndLeft"
                   :headers="uploadHeader"
                   name="uploadFile"
                   :on-success="uploadSuccess"
                   :show-file-list="false">
          <el-button type="info"
                     icon='el-icon-upload2'>导 入
          </el-button>
        </el-upload>
        <el-button @click="loadData" type="info"
                     icon='el-icon-download'>导 出
        </el-button>
        
      </div>
      <div class="flex-box ">
        <!-- 学期 -->
        <el-select v-model="query.yearid"
          clearable
          placeholder="学期">
          <el-option v-for="semester in semesterList"
            :key="semester.id"
            :label="semester.name"
            :value="semester.id"></el-option>
        </el-select>
        <!-- 级别 -->
        <el-select class="picker" v-model="query.level"
          clearable
          placeholder="级别">
          <el-option v-for="level in levelList"
            :key="level.value"
            :label="level.name"
            :value="level.value"></el-option>
        </el-select>
        <!-- 奖状名称 -->
        <el-input v-model="query.name" class="input-box picker" placeholder="奖状名称"></el-input>
        
        
        <el-button @click="loadAwardWiningStudents(1)" class="picker" type="primary">查 询</el-button>
      </div>
    </div>
    <!-- table表格 -->
    <el-table
      :data="wardWiningStudentsTable"
      >
      <el-table-column
        label="学期"
        prop="semester"
        align="center"></el-table-column>
      <el-table-column
        label="获奖年度"
        prop="nd"
        align="center"></el-table-column>
      <el-table-column
        label="获奖类型"
        prop="type"
        align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.type==1">教务教学管理</div>
            <div v-else-if="scope.row.type==2">常规德育管理</div>
            <div v-else>大队部管理</div>
          </template>
      </el-table-column>
        <!-- 学生id -->
        <el-table-column
        label="姓名"
        prop="nickname"
        align="center"></el-table-column>
        <!-- 获奖名称 -->
        <el-table-column
        label="奖项名称"
        prop="name"
        align="center"></el-table-column>
        <!-- 	单位 -->
        <el-table-column
        label="颁奖单位"
        prop="unit"
        align="center"></el-table-column>
        <!-- 级别 -->
        <el-table-column
        label="级别"
        prop="leaveStr"
        align="center"></el-table-column>
        <!-- 	班级id -->
        <el-table-column
        label="班级"
        prop="cname"
        align="center"></el-table-column>
        <el-table-column
        label="证书扫描件等证明材料"
        align="center">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px"
                      v-if="scope.row.url"
                      :src="scope.row.photoUrl"
                      :preview-src-list="scope.row.photoUrlList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
        label="时间"
        prop="opertime"
        align="center"></el-table-column>
        <el-table-column
        label="操作"
        prop="course"
        width="150px"
        align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="editAwardWiningStudents(scope.row)">编辑
            </el-button>
            <el-button
              type="danger"
              size="mini"
              @click="deleteAwardWiningStudents(scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination layout="prev,pager,next" background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadAwardWiningStudents"></el-pagination>
    <!-- 添加获奖学生与编辑获奖学生对话框 -->
    <el-dialog
      :title="awardWiningStudentsForm.tid ? '编辑获奖学生' : '添加获奖学生'"
      :visible.sync="awardWiningStudentsDialogVisible"
      width="600px">
      <div class="center">
        <el-form
          :model="awardWiningStudentsForm"
          ref="awardWiningStudentsFormRef"
          label-width="100px"
          :rules="awardWiningStudentsRules">
          <el-form-item label="学期：" prop="yearid">
            <termSelector :yearid1="awardWiningStudentsForm.yearid" @change="termChange"></termSelector>
          </el-form-item>
          <!-- 获奖年度 -->
          <el-form-item label="获奖年度：" prop="nd">
              <el-date-picker
                v-model="awardWiningStudentsForm.nd"
                type="year"
                placeholder="请选择年份"
                value-format="yyyy">
            </el-date-picker>
          </el-form-item>
          <!-- 获奖类型 -->
          <el-form-item label="获奖类型：" prop="type">
              <el-select v-model="awardWiningStudentsForm.type" clearable
                placeholder="请选择获奖类型">
                <el-option v-for="type in certificateTypeList" :key="type.type"
                      :label="type.certificateName" :value="type.type"/>
              </el-select>
          </el-form-item>
          <!-- 班级Id -->
          <el-form-item label="班级：" prop="classid">
            <classSelector :initialValue="awardWiningStudentsForm.classid" :marginLeft="false" @change="getGradeAndClassID"></classSelector>
          </el-form-item>
          <!-- 学生id -->
           <el-form-item label="学生姓名：" prop="tid">
             <el-select v-model="awardWiningStudentsForm.tid" clearable filterable
                placeholder="请根据班级选择学生">
                <el-option v-for="item in studentList" :key="item.id"
                      :label="item.nickname" :value="item.id"/>
            </el-select>
          </el-form-item>

          <el-form-item label="奖项名称：" prop="name">
            <el-input v-model="awardWiningStudentsForm.name"></el-input>
          </el-form-item>

          <!-- 单位 -->
          <el-form-item label="颁奖单位：" prop="unit">
            <el-input v-model="awardWiningStudentsForm.unit"></el-input>
          </el-form-item>
          <!-- 级别 -->
           <el-form-item label="级别：" prop="level">
             <el-select v-model="awardWiningStudentsForm.level"
              clearable
              placeholder="奖状类型">
              <el-option v-for="level in levelList"
                :key="level.value"
                :label="level.name"
                :value="level.value"></el-option>
            </el-select>
            <!-- <el-input v-model="awardWiningStudentsForm.level"></el-input> -->
          </el-form-item>
          <el-form-item label="证书扫描件等证明材料：">
            <el-upload
              class="upload-demo"
              list-type="picture"
              action="/api/base/uploadByOss.do"
              :headers="{Authorization:token}"
              :limit="1"
              :on-remove="onRemoveFile"
              ref="upload"
              :on-success="enclosureUploadSuccess"
              :data="{path:'awardWiningStudents',name:''}"
              :show-file-list="true">

              <el-button size="small" type="primary">点击上传</el-button>
              <div v-if="awardWiningStudentsForm.url" slot="tip" class="el-upload__tip">已上传附件，可下载附件，也可重新上传</div>
            </el-upload>
            <el-button type="primary"
                     class="load-file"
                     @click="loadFile"
                     v-if="awardWiningStudentsForm.url">下载附件</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 确认与取消 -->
      <span slot="footer" class="dialog-footer">
          <el-button @click="awardWiningStudentsDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addAwardWiningStudentsConfirm">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import classSelector from "@/components/selectors/classSelector";
import termSelector from "@/components/selectors/termSelector";
export default {
  name: "awardWiningStudents",
  components: {classSelector,termSelector},
  data(){
    return {
      token: sessionStorage.getItem("token"),
      uploadHeader: { Authorization: sessionStorage.getItem("token") },
      certificateTypeList:[
        {type:1,certificateName:'教务教学管理'},
        {type:2,certificateName:'常规德育管理'},
        {type:3,certificateName:'大队部管理'}
      ],

      selectedStudent:'',
      page: {pageCount: 1, currentPage: 0},
      queryYear: "",
      // 添加获奖学生的表单
      awardWiningStudentsForm:{
        yearid:'',
        name:'',
        unit:'',
        level:'',
        nd:'',
        tid:'',
        classid:'',
        type:'',
        url:''
      },
      // 添加获奖学生的表单验证规则对象
      awardWiningStudentsRules: {
        yearid: [{required: true}],
        name: [{required: true}],
        unit: [{required: true}],
        level: [{required: true}],
        nd: [{required: true}],
        yearid: [{required: true}],
        tid: [{required: true}],
        type: [{required: true}],
        classid: [{required: true}]
      },
      // 添加获奖学生的表单的显示与隐藏
      awardWiningStudentsDialogVisible:false,
      wardWiningStudentsTable:[],
      queryType:'',
      // 年级列表
      gradeList:[],
      // 班级列表
      classList:[],
      // 学生列表
      studentList:[],
      // 级别列表
      levelList:[
        {value:1,name:'国家'},
        {value:2,name:'省'},
        {value:3,name:'市'},
        {value:4,name:'校'},
      ],
      // 级别
      queryLevel:'',
      // 学期
      semesterList:JSON.parse(sessionStorage.getItem('semesterList')),
      // 查询
      query:{
        yearid:'',
        name:'',
        level:''
      }
    }
  },
  created() {
    // this.getGradeList()
    // this.getClassList()
    this.loadAwardWiningStudents(1)
  },
  methods:{
    termChange(value){
      this.awardWiningStudentsForm.yearid = value
    },
    enclosureUploadSuccess(res, file) {
      // console.log(this.awardWiningTeachersForm.url)
      this.awardWiningStudentsForm.url = file.response.message;
    },
    loadFile() {
      window.open(this.$fileUploadDomain + this.awardWiningStudentsForm.url);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.awardWiningStudentsForm.url = "";
    },
    // 导入
    uploadSuccess(response, file, fileList) {
      if (response.type === 1) {
        this.$message({
          type: "success",
          message: "已上传文件",
        });
        this.loadAwardWiningStudents(1);
      } else {
        this.$message({
          type: "warning",
          message: response.message,
        });
      }
    },
    loadData(){
      const that = this;
      this.$postFile(`base/exportAwardStu.do`)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "获奖学生数据";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    // 导出课获奖学生
    loadTemplate() {
      const that = this;
      this.$postFile(`/base/exportAwardMoBan.do?type=2`)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "获奖学生模板";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    getClassList(){

    },
    // 得到学生列表
    getStudentList(value){
      console.log('1')
      // 通过班级id获取需学生id
      this.$post(`media/queryStudentByClasses.do?classid=${value}`)
      .then(res => {
        // this.awardWiningStudentsForm.tid = res.data
        console.log(res)
        this.studentList = res.data
      })
    },
    // 得到班级id
    getGradeAndClassID(value){
      this.awardWiningStudentsForm.classid = value
      this.getStudentList(value)
    },
    // 获取初始页面数据
    loadAwardWiningStudents(page){
      const that = this;
      let data = {
        indexNo: page,
        yearid:this.query.yearid,
        name:this.query.name,
        level:this.query.level,
      };
      this.$post("/base/queryAwardStudentList.do", data)
      .then((res) => {
        that.page.currentPage = page;
        that.page.pageCount = res.data.pageCount;
        res.data.rows.forEach(item => {
          item.opertime = item.opertime.slice(0,10) + ' ' + item.opertime.slice(11,16)

          item.photoUrl = that.$fileUploadDomain + item.url
          item.photoUrlList = []
          item.photoUrlList.push(that.$fileUploadDomain + item.url)
        })
        that.wardWiningStudentsTable = res.data.rows;
        console.log(that.wardWiningStudentsTable)
      })
      .catch((err) => {
        that.$message({
        type: "warning",
        message: err.message,
        });
      });
    },
    // 点击添加获奖学生按钮
    addAwardWiningStudents(){
      // const that = this
      this.awardWiningStudentsDialogVisible = true
      this.$nextTick(() => {
        this.$refs.awardWiningStudentsFormRef.resetFields()
        this.$refs.upload.clearFiles()
        this.awardWiningStudentsForm.id = ''
        this.awardWiningStudentsForm.classid = 0
        this.awardWiningStudentsForm.tid = ''
        this.awardWiningStudentsForm.name = ''
        this.awardWiningStudentsForm.unit = ''
        this.awardWiningStudentsForm.level = ''
        this.awardWiningStudentsForm.nd = ''
        this.awardWiningStudentsForm.url = ''
      })
      console.log(this.awardWiningStudentsForm)
    },
    // 点击编辑获奖学生按钮
    editAwardWiningStudents(row){
      // 显示对话框
      this.awardWiningStudentsDialogVisible = true
      this.$nextTick(()=>{
        // 把数据赋值给编辑表单
        this.awardWiningStudentsForm.id = row.id
        this.awardWiningStudentsForm.tid = row.tid
        this.awardWiningStudentsForm.name = row.name
        this.awardWiningStudentsForm.unit = row.unit
        this.awardWiningStudentsForm.level = row.level
        this.awardWiningStudentsForm.nd = row.nd.toString()
        this.awardWiningStudentsForm.yearid = row.yearid
        this.awardWiningStudentsForm.tid = row.tid
        this.awardWiningStudentsForm.classid = row.classid
        this.awardWiningStudentsForm.type = row.type
        this.awardWiningStudentsForm.url = row.url
        this.$refs.upload.clearFiles()
      })
      console.log(row)
      
    },
    // 点击删除获奖学生按钮
    deleteAwardWiningStudents(id){
      console.log(id)
      const that = this;
      this.$confirm("是否删除学生获奖?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        this.$post("/base/deleteAwardStudent.do", {id})
        .then((res) => {
          that.$message({
            type: "success",
            message: "删除成功!",
          });
          that.loadAwardWiningStudents(that.page.currentPage);
        })
        .catch((err) => {
          that.$message.error(err.message)
        });
      })
      .catch(() => {
        this.$message({
        type: "info",
        message: "已取消删除",
        });
      });
    },
    // 弹出框确定，提交数据
    addAwardWiningStudentsConfirm(){
      let that = this
      // 表单预校验
      this.$refs.awardWiningStudentsFormRef.validate(validate => {
        // 校验通过
        if(validate){
          // 编辑获奖学生表单提交
          if(this.awardWiningStudentsForm.id){
            console.log(this.awardWiningStudentsForm.tid)
            this.$post('/base/updateAwardStudent.do',this.awardWiningStudentsForm)
            .then(res => {
              console.log(res)
              this.awardWiningStudentsDialogVisible = false;
              // 更新当前页数据列表
              this.loadAwardWiningStudents(this.page.currentPage)
              this.$message({
                type:'success',
                message:'编辑成功！'
              })
            })
            // this.awardWiningStudentsForm.id = -1
            return
          }
          // 添加获奖学生表单提交
          // 获取学期，班级，学生id再传数据，

          this.$post('/base/insertAwardStudent.do',this.awardWiningStudentsForm)
          .then(res => {
            console.log(res)
            this.awardWiningStudentsDialogVisible = false;
            this.loadAwardWiningStudents(1);
            this.$message({
              type:'success',
              message:'添加成功！'
            })
          })
          .catch((err) => {
            // that.$message.error(err.message.data);
          });

        }

      })
    }
  }
}
</script>
<style scoped>

.left{
  display: flex;
}

.display {
  display: inline-block;
}

.marginRightAndLeft {
  margin-right: 10px;
  margin-left: 10px;
}

.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}
</style>
